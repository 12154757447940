var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Draggable',{class:[
    'cal-cell shift-cell flex',
    { 'drop-zone': _vm.isDropZone },
    { 'illegal-zone': !_vm.isDropZone && !_vm.isDraggingFromHere },
    { 'drag-start-zone': _vm.isDraggingFromHere },
    { 'good-ol-days': _vm.isInThePast },
    { 'weekend-shift': _vm.isWeekend },
    { 'sunday-shift': _vm.isSunday },
    { 'inactive-foreman': _vm.inactiveForeman },
    { 'last-cell': _vm.isLastCell },
    `shift-count-${_vm.reports.length}`,
  ],style:(_vm.flexBasisStyle),attrs:{"list":_vm.reports,"sort":false,"draggable":".shift.mutable:not(.cannot-drag)","group":"locations","move":_vm.onMove,"disabled":!_vm.canEditScheduler},on:{"start":_vm.onStart,"end":_vm.onEndCallback,"change":_vm.changeReport}},[(_vm.isForPrint && _vm.reports.length > 2)?_vm._l((_vm.reportsByJob),function({ reports: groupedReports, JOB_NUMBER }){return _c('div',{key:JOB_NUMBER},[_c('CalCellReportGroup',{attrs:{"job-number":JOB_NUMBER,"reports":groupedReports,"foreman-id":_vm.foremanId,"is-in-the-past":_vm.isInThePast}})],1)}):_vm._l((_vm.reports),function(report){return _c('CalCellReport',{key:`${report.ID}-${_vm.foremanId}`,attrs:{"is-in-the-past":_vm.isInThePast,"report":report,"location":report.locationData}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }