<template>
  <Draggable
    :class="[
      'cal-cell shift-cell flex',
      { 'drop-zone': isDropZone },
      { 'illegal-zone': !isDropZone && !isDraggingFromHere },
      { 'drag-start-zone': isDraggingFromHere },
      { 'good-ol-days': isInThePast },
      { 'weekend-shift': isWeekend },
      { 'sunday-shift': isSunday },
      { 'inactive-foreman': inactiveForeman },
      { 'last-cell': isLastCell },
      `shift-count-${reports.length}`,
    ]"
    :list="reports"
    :sort="false"
    draggable=".shift.mutable:not(.cannot-drag)"
    group="locations"
    :move="onMove"
    :disabled="!canEditScheduler"
    :style="flexBasisStyle"
    @start="onStart"
    @end="onEndCallback"
    @change="changeReport"
  >
    <template v-if="isForPrint && reports.length > 2">
      <div
        v-for="{ reports: groupedReports, JOB_NUMBER } in reportsByJob"
        :key="JOB_NUMBER"
      >
        <CalCellReportGroup
          :job-number="JOB_NUMBER"
          :reports="groupedReports"
          :foreman-id="foremanId"
          :is-in-the-past="isInThePast"
        />
      </div>
    </template>
    <template v-else>
      <CalCellReport
        v-for="report in reports"
        :key="`${report.ID}-${foremanId}`"
        :is-in-the-past="isInThePast"
        :report="report"
        :location="report.locationData"
      />
    </template>
  </Draggable>
</template>

<script>
// import _difference from 'lodash/difference'
// import _flattenDeep from 'lodash/flattenDeep'
// import _uniq from 'lodash/uniq'

import Draggable from 'vuedraggable'
import { ShiftTypes } from '@constants/knack'
import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _debounce from 'lodash/debounce'
import { createHelpers } from 'vuex-map-fields'
import CalCellReportGroup from './CalCellReportGroup'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})
const CalCellReport = () =>
  import(/* webpackChunkName: 'CalCellReport' */ '@scheduler/CalCellReport')

export default {
  name: 'CalCell',
  components: {
    Draggable,
    CalCellReport,
    CalCellReportGroup,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    isWeekend: {
      type: Boolean,
      default: false,
    },
    isSunday: {
      type: Boolean,
      default: false,
    },
    isInThePast: {
      type: Boolean,
      default: false,
    },
    inactiveForeman: {
      type: Boolean,
      default: false,
    },
    foreman: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
    totalDatesVisible: {
      type: Number,
      default: 8,
    },
    numSundays: {
      type: Number,
      default: 1,
    },
    isLastCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDraggingFromHere: false,
    }
  },
  computed: {
    ...mapGetters([
      'theDragObject',
      'theDragObjectShiftTypes',
      'canEditScheduler',
    ]),
    ...mapFields(['showScheduleInThePastModal', 'moveToThePastPayload']),
    reportsByJob() {
      let groupedByJob = _groupBy(this.reports, 'JOB_NUMBER')
      // This returns a collection where the shape looks like
      // [
      //   { JOB_NUMBER: '1234', reports : [{ ... }, { ... }, { ... }] }
      //   { JOB_NUMBER: '2345', reports : [{ ... }, { ... }] }
      //   ...
      // ]
      const transformedData = _map(groupedByJob, (reports, JOB_NUMBER) => ({
        JOB_NUMBER,
        reports,
      }))

      return transformedData
    },
    isDropZone() {
      // let hasMoreThanOneReport = this.reports.length > 1
      if (
        // hasMoreThanOneReport || // disabled to allow any number of reports
        this.inactiveForeman
      )
        return false

      if (!this.theDragObjectShiftTypes) {
        console.warn(
          'ERROR : "isDragging" was set without setting the dragged Object!'
        )
        return false
      }

      if (this.isDraggingFromHere) return true

      // If dragging item is weekends only, disallow if this isn't a weekend
      let isDraggingReportWeekendOnly =
        this.theDragObjectShiftTypes.length === 1 &&
        this.theDragObjectShiftTypes[0] === ShiftTypes.WEEKEND
      if (!this.isWeekend && isDraggingReportWeekendOnly) return false

      // If this is a weekend with one report, don't allow any more
      // if (this.isWeekend && this.reports.length === 1) return false

      // If dragging item is weekdays only, disallow if this is a weekend
      let isDraggingReportWeekdayOnly =
        this.theDragObjectShiftTypes.length === 1 &&
        [ShiftTypes.WEEKDAY, ShiftTypes.NIGHTS].includes(
          this.theDragObjectShiftTypes[0]
        )
      if (this.isWeekend && isDraggingReportWeekdayOnly) return false

      // If dragging item is weekdays only, disallow if this is a weekend
      if (
        this.isWeekend &&
        !this.theDragObjectShiftTypes.includes(ShiftTypes.WEEKEND)
      ) {
        return false
      }

      return true

      // if (this.reports.length) {
      //   let myShiftTypes = _uniq(
      //     _flattenDeep(
      //       this.reports.map((r) =>
      //         r.locationData.PERMIT__WORK_HOURS
      //           ? r.locationData.PERMIT__WORK_HOURS.map((shift) => {
      //               return shift
      //                 ? shift.split(';').map((token) => token.trim())
      //                 : ''
      //             })
      //           : []
      //       )
      //     )
      //   )

      //   // let iHaveNightShifts = myShiftTypes.includes(ShiftTypes.NIGHTS)
      //   let theDragObjectIsNight = this.theDragObjectShiftTypes.includes(
      //     ShiftTypes.NIGHTS
      //   )

      //   // Return FALSE if the drag Report and our Reports are both
      //   the same WRT night shift
      //   (e.g. 'Nights' && 'Nights' or 'M-F' && 'M-F')
      //   if (iHaveNightShifts === theDragObjectIsNight) return false
      // }

      // return this.reports.length <= 1
    }, // isDropZone

    flexBasisStyle() {
      if (this.isSunday) {
        return `flex-basis: 50px;`
      }
      let numStandardCells = this.totalDatesVisible - this.numSundays

      let foremanCellWidth = 80
      const nonStandardCellWidthInPx = this.numSundays * 50 + foremanCellWidth

      const widthToSubtractForEachStandardCell =
        nonStandardCellWidthInPx / numStandardCells

      const flexBasisPercentage = 100 / numStandardCells
      const flexBasis = `calc(${flexBasisPercentage}% - ${widthToSubtractForEachStandardCell}px)`

      return { 'flex-basis': flexBasis }
    },
    foremanId() {
      return _get(this, 'report.FOREMAN[0].id', 'new')
    }, // foremanId
    isForPrint() {
      return this.$route.path === '/print-scheduler'
    },
  },
  methods: {
    ...mapActions(['startDrag', 'endDrag', 'setTheDragObject']),
    onStart(evt) {
      // console.log('reports', this.reports)
      // console.log('onStart evt', evt)
      this.isDraggingFromHere = true
      let theReport = this.reports[evt.oldIndex]
      this.setTheDragObject(theReport)
      this.startDrag()
    }, // onStart
    changeReport(evt) {
      // if an element was added
      if (evt.added) {
        if (this.isInThePast) {
          this.showScheduleInThePastModal = true
          this.moveToThePastPayload = {
            foreman: this.foreman,
            index: this.index,
            draggedObj: evt.added.element,
          }
        } else {
          // eslint-disable-next-line
          // console.log('[changedReport:added]', evt)
          this.$emit('reportAdded', evt.added.element)
        }
      }
      // If an element was removed
      else if (evt.removed) {
        // eslint-disable-next-line
        // console.log('[changedReport:removed]', evt)
      }
    },
    onEndCallback(evt) {
      // eslint-disable-next-line
      // console.log('[ onEnd ]', evt)
      this.endDrag()
      this.isDraggingFromHere = false
      this.$emit('onEnd', evt)
      // return false
      // return false; — for cancel
    },
    onMove(evt) {
      // eslint-disable-next-line
      // console.log('[ CalCell onMove ]', evt)
      if (evt.to.classList.contains('locations')) {
        let theReport = evt.draggedContext.element
        if (
          theReport.NUMBER_OF_EQUIPMENT_USAGES > 0 ||
          theReport.NUMBER_OF_LABOR_ASSIGNMENTS > 0 ||
          theReport.NUMBER_OF_MATERIAL_USAGES > 0
        ) {
          this.notifyCantDelete()
          return false
        } else {
          return true
        }
      } else if (
        evt.to === evt.from ||
        evt.to.classList.contains('locations') ||
        evt.to.classList.contains('drop-zone')
      ) {
        return true
      } else {
        return false
      }
    }, // onMove
    notifyCantDelete: _debounce(function() {
      this.$notify({
        type: 'warn',
        title:
          'The assets on this report must be cleared before it can be deleted.',
      })
    }, 100),
  },
}
</script>

<style lang="scss">
.cal-cell {
  $w: 100% / 15;

  position: relative;
  flex: 0 0 $w;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  text-align: center;
  border-left: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;

  &.last-cell {
    border-right: 1px solid #c3c3c3;
  }
}
.shift-cell {
  padding: 1px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;

  .location-item.sortable-ghost {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  &.weekend-shift {
    background-color: #e3e3e3;
  }
  &.sunday-shift {
    flex: 0 1 50px;
    min-width: 50px;
  }
  &.inactive-foreman {
    background-color: #c6c6c6 !important;
  }
  &.shift-count-1 {
    .shift {
      flex-basis: 100%;
    }
  }
  &.shift-count-2 {
    .shift {
      flex-basis: 50%;
    }
  }
  &.shift-count-3 {
    min-height: 160px;
    .shift {
      flex-basis: 33%;
    }
  }
  .shift:not(:last-child) {
    @media print {
      border-bottom: 1px solid #9e9e9e;
      border-radius: 0 !important;
    }
  }

  @media print {
    &.cal-cell {
      border-left-color: hsl(0, 0, 85%) !important;
      border-right-color: hsl(0, 0, 85%) !important;
      border-top: 1px dashed hsl(0, 0, 20%) !important;
    }
  }
} // .shift-cell
.crew-calendar.dragging .cal-cell {
  &.drop-zone {
    $drop-zone: #defadb;

    background-color: $drop-zone;
    border-color: darken($drop-zone, 20%);
    &.weekend-shift {
      background-color: darken($drop-zone, 7%);
    }
    &.drag-start-zone {
      $drag-start-zone: #f5f5ff;

      background-color: $drag-start-zone;
      border-color: darken($drag-start-zone, 20%);
      &.weekend-shift {
        background-color: darken($drag-start-zone, 7%);
      }
    }
  }
  &.illegal-zone {
    $illegal-zone: #f9e0e0;

    background-color: $illegal-zone;
    border-color: darken($illegal-zone, 20%);
    &.weekend-shift {
      background-color: darken($illegal-zone, 7%);
    }
    &.good-ol-days {
      $good-ol-days: #f7eafe;

      background-color: $good-ol-days;
      border-color: darken($good-ol-days, 10%);
      &.weekend-shift {
        background-color: darken($good-ol-days, 7%);
        border-color: darken($good-ol-days, 12%);
      }
    }
  }
}
</style>
