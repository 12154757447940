<template>
  <VFlex
    class="shift elevation-1 needs-setup text-md-left"
    :class="[isImmutable ? 'immutable' : 'mutable']"
  >
    <span class="cal-cell-data job-number"
      >{{ jobNumber }}
      <span v-if="reports.length > 1">(x{{ reports.length }})</span></span
    >
    <div v-for="report in reports" :key="`${report.ID}-${foremanId}`">
      <div class="cal-cell-data small-data">{{
        report.LOCATION[0].identifier
      }}</div>
    </div>
  </VFlex>
</template>

<script>
import { ReportStatuses } from '@constants/knack'
import _get from 'lodash/get'

export default {
  name: 'CalCellReportGroup',
  props: {
    reports: {
      type: Array,
      required: true,
      default: () => [],
    },
    foremanId: {
      type: String,
      required: true,
      default: '',
    },
    jobNumber: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    isImmutable() {
      return this.hasLockedReports || this.hasCompletedReports
    }, // isImmutable
    hasLockedReports() {
      return this.reports.some((report) => this.isLocked(report))
    }, // hasLockedReports
    hasCompletedReports() {
      return this.reports.some((report) => this.isCompleted(report))
    }, // hasCompletedReports
  },
  methods: {
    isCompleted(report) {
      return _get(report, 'REPORT_STATUS') === ReportStatuses.COMPLETED
    }, // isCompleted
    isLocked(report) {
      return _get(report, 'REPORT_STATUS') === ReportStatuses.LOCKED
    }, // isLocked
  },
}
</script>
